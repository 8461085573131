import React, { useState, useRef } from "react";
import Cookies from "universal-cookie";
import useAPI from "../hooks/useAPI";
import useFetchPolling from "../hooks/useFetchPolling";
import FormElement from "../components/FormElement";
import LoadingSpinner from "./LoadingSpinner";
//import { navigate } from "@reach/router";

const StreamView = ({ streamId }) => {
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const { clientLogin, videoStreamSetup } = useAPI();
  const [showSpinner, setShowSpinner] = useState(true);
  const checkStreamAvailability = useFetchPolling(
    0,
    500,
    sorryNoStreamAvailable
  );
  const setStreamKeepAlive = useFetchPolling(15000, Infinity, null);
  const cookies = new Cookies();
  const clientLoginForm = useRef();
  const vidContainer = useRef();
  const Hls = window.Hls;
  const location = window.location;

  function sorryNoStreamAvailable() {
    setError("Sorry, there is no stream available.");
    setShowSpinner(false);
  }

  function initHls() {
    if (Hls.isSupported()) {
      const config = {
        initialLiveManifestSize: 1,
        maxMaxBufferLength: 15,
        liveSyncDuration: 1,
        maxStarvationDelay: 2,
        maxBufferLength: 5,
        maxBufferSize: 1500,
        maxLoadingDelay: 2,
      };
      let hls = new Hls(config);
      hls.attachMedia(vidContainer.current);
      hls.on(Hls.Events.MEDIA_ATTACHED, function () {
        console.log("video and hls.js are now bound together !");
        //hls.loadSource("https://karpfenteich.eu/stream1/index.m3u8");
        let uri = location.href.replace(
          `/${streamId}`,
          `${streamId}/index.m3u8`
        );
        hls.loadSource(uri);
        hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log(
            "manifest loaded, found " + data.levels.length + " quality level"
          );

          let formData = new FormData();
          formData.append("stream-id", streamId);
          setStreamKeepAlive("/backend/keep-alive", {
            method: "POST",
            body: formData,
          });

          setShowSpinner(false);
          vidContainer.current.play();
        });
      });
    }
  }

  const onSubmit = async (evt) => {
    evt.preventDefault();
    setError("");
    const formData = new FormData(clientLoginForm.current);
    const req = await clientLogin(formData);
    if (req.isAuthenticated) {
      cookies.set("token", req.token);
      formData.append("token", req.token);
      setIsAuth(true);
      videoStreamSetup(formData)
        .then((res) => {
          if (
            res.hasOwnProperty("isAuthenticated") &&
            res.isAuthenticated === false
          ) {
            console.log(res);
            location.reload();
          }

          // No onBeforeUnload is needed with keep-alive
          //window.addEventListener("beforeunload", beforeUnloadWin);

          let formData = new FormData();
          formData.append("stream-id", streamId);

          checkStreamAvailability(
            "/backend/is-stream-avail",
            {
              method: "POST",
              body: formData,
            },
            { prop: "stream_is_avail", state: true },
            initHls
          );
        })
        .catch((err) => console.log(err));
    } else {
      setError("Wrong Credentials! Please try agian.");
    }
  };

  return (
    <div className="stream-view-container">
      {isAuth ? (
        <div className="container" style={{ textAlign: "center" }}>
          {showSpinner ? <LoadingSpinner /> : null}
          {error ? (
            <div className="error-message-container">
              <p>{error}</p>
            </div>
          ) : null}
          <video
            id="video"
            width="640"
            controls
            autoPlay
            ref={vidContainer}
          ></video>
        </div>
      ) : (
        <form id="login-form" onSubmit={onSubmit} ref={clientLoginForm}>
          <h2 className="title">Streaming Lab Login</h2>
          {error ? (
            <div className="error-message-container">
              <p>{error}</p>
            </div>
          ) : null}
          <FormElement
            icon="vpn_key"
            id="password"
            type="password"
            name="password"
            placeholder="Password"
            autoComplete="current-password"
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
            required
          />
          <FormElement
            name="id"
            value={streamId}
            type="hidden"
            parentClass="no-margin hidden-before-after"
          />
          <FormElement
            id="submit"
            type="submit"
            parentClass="hidden-before-after"
          />
        </form>
      )}
    </div>
  );
};

export default StreamView;

// fmpeg -fflags nobuffer -rtsp_transport tcp -i ./Big_Buck_Bunny_1080_10s_1MB.mp4 -vsync 0 -copyts -vcodec copy -strict experimental -c:a aac -hls_flags delete_segments -hls_time 0.5 -hls_list_size 24 -hls_wrap 50 -start_number 1 /var/www/vhost/karpfenteich.eu/httpdocs/public/stream1/index.m3u8
// ffmpeg -fflags nobuffer -rtsp_transport tcp -i rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mov -vsync 0 -copyts -vcodec copy -strict experimental -c:a aac -hls_flags delete_segments -hls_time 0.5 -hls_list_size 24 -hls_wrap 50 -start_number 1 /var/www/vhost/karpfenteich.eu/httpdocs/public/stream1/index.m3u8
