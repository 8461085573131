import React from "react";

function KaeptnLogo(props) {
  return (
    <svg viewBox="0 0 200 56" {...props}>
      <g className="prefix__cls-2">
        <path
          className="prefix__cls-3"
          d="M16.52 28.2h9.01L14.4 41.45l12.14 14.73h-9.43L7.72 44.05h-.07v12.13H.37V28.2h7.28v11.54h.07l8.8-11.54zM43 51.32l-1.93 4.86h-7.74l10.76-28H52l10.54 28h-7.72L53 51.32zm5.08-14.47H48l-3 8.91h6zm33.57-2.49v4.68H92v6.16H81.65v4.82h10.79v6.16H74.38V28.2h18.06v6.16H81.65z"
        />
        <g className="prefix__cls-2">
          <path
            className="prefix__cls-3"
            d="M107.94 28.2h11.13c6 0 10.17 2.78 10.17 9.2 0 6.61-3.56 9.5-9.91 9.5h-4.12v9.28h-7.27zm7.27 12.8h1.22c2.64 0 5.23 0 5.23-3.41s-2.41-3.56-5.23-3.56h-1.22zm36.69 15.18h-7.28V34.36h-6.87V28.2h21.02v6.16h-6.87v21.82zm19.55-27.98h7.27l13.32 17.11h.08V28.2h7.27v27.98h-7.27L178.8 39.04h-.08v17.14h-7.27V28.2zm-12.68-6.15V1.07l16.68 20.98h-16.68z"
          />
        </g>
      </g>
    </svg>
  );
}

export default KaeptnLogo;
