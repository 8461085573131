import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "@reach/router";
import useAPI from "../hooks/useAPI";
import Cookies from "universal-cookie";
import LoadingSpinner from "./LoadingSpinner";
import StreamCard from "./StreamCard";
import FormElement from "./FormElement";

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(true);
  const [streams, setStreams] = useState([]);
  const [streamURL, setStreamURL] = useState("");
  const [password, setPassword] = useState("");
  const [streamId, setStreamId] = useState("");
  const streamEditModal = useRef();
  const dismissBtn = useRef();
  const clipbaordCopy = useRef();
  const {
    getStreamDbEntries,
    setStreamDbEntries,
    isAuthenticated,
    resetStream,
  } = useAPI();
  const cookies = new Cookies();
  const token = cookies.get("token");
  const location = window.location;

  const gettingDbStreams = async (token) => {
    setShowSpinner(true);
    return await getStreamDbEntries(token);
  };

  const isAuth = async (token) => await isAuthenticated(token);

  useEffect(() => {
    isAuth(token).then((res) => {
      if (res.isAuthenticated) {
        gettingDbStreams(token).then((res) => {
          setStreams(res);
          setShowSpinner(false);
        });
      } else {
        navigate("/");
      }
    });
    // eslint-disable-next-line
  }, []);

  const onEditClick = (streamId, streamURL, streamPwd) => (evt) => {
    setStreamURL(streamURL);
    setPassword(streamPwd);
    setStreamId(streamId);
    let modal = streamEditModal.current.parentElement;
    modal.style.zIndex = 10;
    modal.classList.toggle("hidden");
  };

  const onCopyEmailClick = (streamId, streamPwd) => (evt) => {
    let anchor = document.createElement("a");
    const url = location.href.replace("dashboard", "stream/" + streamId);
    anchor.href =
      "mailto:mustermann@mail.de?subject=KAEPTN Streaming Zugangsdaten&body=Hier ist ihr Link:%0D%0A%0D%0A" +
      url +
      "%0D%0A%0D%0AIhr Passwort lautet:%0D%0A%0D%0A" +
      streamPwd;
    anchor.click();
    anchor = null;
  };

  const pwdGen = (evt) => {
    const randomPwdString = Math.random().toString(36).slice(-8);
    setPassword(randomPwdString);
  };

  const copyCredentialsClick = (streamId, streamPwd) => (evt) => {
    const url = location.href.replace("dashboard", "stream/" + streamId);

    clipbaordCopy.current.value =
      "Hier ist ihr Link:\n\n" +
      url +
      "\n\nIhr Passwort lautet:\n\n" +
      streamPwd;
    clipbaordCopy.current.select();
    document.execCommand("copy");
  };

  const openBrowserClick = (streamId) => (evt) => {
    console.log(location.href.replace("dashboard", "stream/"));
    const newTab = document.createElement("a");
    newTab.href =
      location.href.replace("dashboard", "stream/") + streamId.toString();
    newTab.target = "_blank";
    newTab.rel = "noopener";
    newTab.click();
  };

  const resetStreamClick = (streamId, streamUrl) => (evt) => {
    console.log({ streamUrl });
    const formData = new FormData();
    formData.append("stream-id", streamId);
    formData.append("stream-url", streamUrl);
    setShowSpinner(true);
    resetStream(formData)
      .then((res) => {
        console.log(res);
        if (res.hasOwnProperty("found-pid")) {
          setShowSpinner(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (evt) => {
    evt.preventDefault();
    const formData = new FormData(streamEditModal.current);
    dismissBtn.current.click();
    setShowSpinner(false);
    setStreamDbEntries(formData, token)
      .then((res) => {
        gettingDbStreams(token).then((res) => {
          setStreams(res);
          setShowSpinner(false);
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="container">
      {showSpinner ? <LoadingSpinner /> : null}
      <div id="dashboard-container" className={showSpinner ? "hidden" : ""}>
        <textarea
          ref={clipbaordCopy}
          style={{ position: "absolute", top: "-1000px", left: "-1000px" }}
        ></textarea>
        <div id="stream-edit-modal" className="hidden">
          <form id="stream-edit-form" onSubmit={onSubmit} ref={streamEditModal}>
            <span
              className="dismiss-btn icon icon-clear"
              ref={dismissBtn}
              onClick={() => {
                let modal = streamEditModal.current.parentElement;
                modal.classList.toggle("hidden");
                modal.style.zIndex = -1;
                setStreamURL("");
                setPassword("");
              }}
            ></span>
            <FormElement
              icon="ondemand_video"
              id="stream-url"
              type="text"
              name="url"
              placeholder="Stream URL"
              autoComplete="stream-url"
              value={streamURL}
              onChange={(evt) => setStreamURL(evt.target.value)}
              required
            />
            <FormElement
              icon="vpn_key"
              iconBtn="security"
              id="password"
              type="text"
              name="password"
              placeholder="New Password"
              autoComplete="current-password"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
              pwdGen={pwdGen}
              required
            />
            <FormElement
              name="id"
              type="hidden"
              value={streamId}
              parentClass="hidden-before-after no-margin"
            />
            <FormElement
              id="submit"
              type="submit"
              value="Speichern"
              parentClass="hidden-before-after"
            />
          </form>
        </div>

        {streams
          ? streams.map((stream) => (
              <StreamCard
                key={stream.id}
                streamId={stream.id}
                name={stream.name}
                url={stream.url}
                password={stream.password}
                thumb_path={stream.thumb_path}
                onEditClick={onEditClick}
                onCopyEmailClick={onCopyEmailClick}
                copyCredentialsClick={copyCredentialsClick}
                resetStreamClick={resetStreamClick}
                openBrowserClick={openBrowserClick}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default Dashboard;
