import React, { useState, useEffect } from "react";

const StreamCard = ({
  streamId,
  name,
  url,
  thumb_path,
  password,
  onEditClick,
  onCopyEmailClick,
  copyCredentialsClick,
  resetStreamClick,
  openBrowserClick,
}) => {
  const [streamURL, setURL] = useState();
  //const [streamName, setName] = useState();
  const [streamPassword, setStreamPassword] = useState();

  useEffect(() => {
    setURL(url);
    setStreamPassword(password);
    //setName(name);
  }, [url, password]);

  return (
    <div className="stream-item">
      <img src={thumb_path} alt={name} />
      <div className="stream-content">
        <h3 className="title noselect cap">{`${name}`}</h3>
        <button
          className="btn"
          onClick={onEditClick(streamId, streamURL, streamPassword)}
        >
          Edit Stream
        </button>
        <button
          className="btn"
          onClick={onCopyEmailClick(streamId, streamPassword)}
        >
          Copy to Email
        </button>
        <button
          onClick={copyCredentialsClick(streamId, streamPassword)}
          className="btn"
        >
          Copy Credentials
        </button>
        <button onClick={resetStreamClick(streamId, streamURL)} className="btn">
          Reset Stream
        </button>
        <button onClick={openBrowserClick(streamId)} className="btn">
          Open in Browser
        </button>
      </div>
    </div>
  );
};

export default StreamCard;
