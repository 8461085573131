const useFetchPolling = (fetchInterval, retryLimit, onFailure) => {
  const isFunction = (fn) => fn && {}.toString.call(fn) === "[object Function]";
  const calcAttempts = (limit, attempts) =>
    limit && isFinite(limit) ? attempts + 1 : 1;

  const pollFetcher = (url, options, condition, onSuccess) => {
    let attempts = 0;
    (function retry(attempts) {
      setTimeout(
        async function () {
          const req = await fetch(url, options);
          if (req.ok && req.status === 200) {
            const res = await req.json();
            if (condition && res[condition.prop] === condition.state) {
              isFunction(onSuccess) && onSuccess();
            } else {
              attempts = calcAttempts(retryLimit, attempts);
              if (attempts <= retryLimit) {
                console.log({ attempts });
                retry(attempts);
              } else {
                isFunction(onFailure) && onFailure();
              }
            }
          }
        },
        fetchInterval ? attempts * fetchInterval : 0
      );
    })(attempts);
  };

  return pollFetcher;
};

export default useFetchPolling;
