import React from "react";

function LoadingSpinner() {
  return (
    <div className="loading-container">
      <div className="loading-spinner">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ margin: "auto", background: "#f6fcf7" }}
          display="block"
          preserveAspectRatio="xMidYMid"
          viewBox="0 0 100 100"
        >
          <path fill="#626c66" d="M19 19h20v20H19z">
            <animate
              attributeName="fill"
              begin="0s"
              calcMode="discrete"
              dur="1s"
              keyTimes="0;0.125;1"
              repeatCount="indefinite"
              values="#222222;#626c66;#626c66"
            ></animate>
          </path>
          <path fill="#626c66" d="M40 19h20v20H40z">
            <animate
              attributeName="fill"
              begin="0.125s"
              calcMode="discrete"
              dur="1s"
              keyTimes="0;0.125;1"
              repeatCount="indefinite"
              values="#222222;#626c66;#626c66"
            ></animate>
          </path>
          <path fill="#626c66" d="M61 19h20v20H61z">
            <animate
              attributeName="fill"
              begin="0.25s"
              calcMode="discrete"
              dur="1s"
              keyTimes="0;0.125;1"
              repeatCount="indefinite"
              values="#222222;#626c66;#626c66"
            ></animate>
          </path>
          <path fill="#626c66" d="M19 40h20v20H19z">
            <animate
              attributeName="fill"
              begin="0.875s"
              calcMode="discrete"
              dur="1s"
              keyTimes="0;0.125;1"
              repeatCount="indefinite"
              values="#222222;#626c66;#626c66"
            ></animate>
          </path>
          <path fill="#626c66" d="M61 40h20v20H61z">
            <animate
              attributeName="fill"
              begin="0.375s"
              calcMode="discrete"
              dur="1s"
              keyTimes="0;0.125;1"
              repeatCount="indefinite"
              values="#222222;#626c66;#626c66"
            ></animate>
          </path>
          <path fill="#626c66" d="M19 61h20v20H19z">
            <animate
              attributeName="fill"
              begin="0.75s"
              calcMode="discrete"
              dur="1s"
              keyTimes="0;0.125;1"
              repeatCount="indefinite"
              values="#222222;#626c66;#626c66"
            ></animate>
          </path>
          <path fill="#626c66" d="M40 61h20v20H40z">
            <animate
              attributeName="fill"
              begin="0.625s"
              calcMode="discrete"
              dur="1s"
              keyTimes="0;0.125;1"
              repeatCount="indefinite"
              values="#222222;#626c66;#626c66"
            ></animate>
          </path>
          <path fill="#626c66" d="M61 61h20v20H61z">
            <animate
              attributeName="fill"
              begin="0.5s"
              calcMode="discrete"
              dur="1s"
              keyTimes="0;0.125;1"
              repeatCount="indefinite"
              values="#222222;#626c66;#626c66"
            ></animate>
          </path>
        </svg>
      </div>
    </div>
  );
}

export default LoadingSpinner;
