import { useCallback } from "react";

const useAPI = () => {
  const apiURI = "/backend";

  const sendData = useCallback(async (url, options) => {
    const req = await fetch(url, options);
    if (req.ok && req.status === 200) {
      return await req.json();
    }
    return { status: req.status, ok: req.ok, text: req.statusText };
  }, []);

  //adminLogin: `${apiURI}/admin-login`,

  return {
    adminLogin: (formData) =>
      sendData(`${apiURI}/admin-login`, {
        method: "POST",
        body: formData,
      }),
    clientLogin: (formData) =>
      sendData(`${apiURI}/client-login`, {
        method: "POST",
        body: formData,
      }),
    isAuthenticated: () =>
      sendData(`${apiURI}/is-authenticated`, {
        method: "POST",
        credentials: "include",
      }),
    getStreamDbEntries: () =>
      sendData(`${apiURI}/get-stream-db-entries`, {
        method: "POST",
        credentials: "include",
      }),
    setStreamDbEntries: (formData) =>
      sendData(`${apiURI}/set-stream-db-entries`, {
        method: "POST",
        credentials: "include",
        body: formData,
      }),
    videoStreamSetup: (formData) =>
      sendData(`${apiURI}/video-stream-setup`, {
        method: "POST",
        body: formData,
      }),
    resetStream: (formData) =>
      sendData(`${apiURI}/reset-stream`, {
        method: "POST",
        body: formData,
      }),
  };
};

export default useAPI;
